import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
// @mui
import { Typography, Stack, Button } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
import { useAuthContext } from '../../auth/useAuthContext';
import { PATH_AUTH } from '../../routes/paths';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
  showBack: PropTypes.bool,
};

export default function LoginLayout({ children, illustration, title, showBack = false }) {
  const navigate = useNavigate();
  const { logout } = useAuthContext();

  const handlelogout = async () => {
    logout();
    navigate(PATH_AUTH.login, { replace: true });
  };

  return (
    <StyledRoot>
      {showBack ? (
        <Stack
          spacing={2}
          sx={{
            position: 'absolute',
            mt: { xs: 2, md: 5 },
            ml: { xs: 2, md: 5 },
          }}
        >
          <Button
            variant="text"
            color="secondary"
            startIcon={<Iconify icon="mingcute:left-fill" />}
            onClick={handlelogout}
          >
            Back
          </Button>
        </Stack>
      ) : (
        <Logo
          sx={{
            zIndex: 9,
            position: 'absolute',
            mt: { xs: 1.5, md: 5 },
            ml: { xs: 2, md: 5 },
          }}
        />
      )}
      {/*  <StyledSection>
        <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          {title || 'Sign Up to create an account.'}
        </Typography>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/illustration_dashboard.png'}
          sx={{ maxWidth: 720 }}
        />

        <StyledSectionBg />
      </StyledSection> */}

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
