import { Helmet } from 'react-helmet-async';
import { Typography, Stack } from '@mui/material';
// sections
import Login from '../../sections/auth/Login';
import { BRAND } from '../../config-global';
import LoginLayout from '../../layouts/login';
import AuthWithInstagram from '../../sections/auth/AuthWithInstagram';

// ----------------------------------------------------------------------

export default function ConnectInstagramPage() {
  return (
    <>
      <Helmet>
        <title>{`Login | ${BRAND.name}`}</title>
      </Helmet>

      <LoginLayout showBack>
        <Stack spacing={2} sx={{ mb: 2, position: 'relative' }}>
          <Typography variant="h4">Connect your Instagram</Typography>
        </Stack>
        <AuthWithInstagram />
      </LoginLayout>
    </>
  );
}
